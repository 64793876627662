<template>
  <div>
    <h2 class="wsDARKER mb-5"
        :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
        :style="`font-size: ${!SM ? 24 : 19}px`"
    >
      {{  $t('wsu_structure.specialty.career.title')  }}
    </h2>

    <div v-for="(item,index) in entity.content" :key="index">
      <h3 v-for="(phone,i) in item.phone" :key="i+ 'phone'"
          class="wsACCENT" >
        {{ phone }}
      </h3>

      <div class="d-flex align-center">
        <img
            v-if="item.img"
            :src="item.img"
            width="56px"
            height="56px"
            class="mr-5"
        >
        <div>
          <h4>{{ item.sphere }}</h4>
          <h5 class="font-weight-regular">{{ item.positions }}</h5>
        </div>
      </div>

      <v-divider
          v-if="index < entity.content.length -1"
          class="my-10" style="border-color: var(--wsBACKGROUND)"
      />

    </div>


  </div>

</template>

<script>
export default {
  name: "programCareer",
  props : {
    entity : {
      type : Object,
      default() { return {}}
    }
  }
}
</script>



<style scoped>

</style>